
@font-face {
  font-family: 'Nova Mono';
  src: url(./assets/fonts/nova-mono/NovaMono-Regular.ttf);
}

body {
  margin: 0;
  font-family: 'Nova Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after {
  box-sizing: border-box;
}
